<template lang="pug">
    transition(name="component-aside")
        aside.main-aside(v-if="ativo", ref="componentAside", @click.self="$store.commit('toggleAside', false)", @keydown.esc="$store.commit('toggleAside', false)", tabindex="0")
            .main-content
                button(type="button", title="Fechar", @click="$store.commit('toggleAside', false)").fechar
                    FontAwesomeIcon(:icon="icons.faXmark")
                slot
</template>

<script>
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-aside",
    props: {
        ativo: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            icons: {
                faXmark,
            },
        }
    },
}
</script>

<style lang="stylus" scoped src="./Aside.styl"></style>
