<template lang="pug">
	Container
		main#coupons
			Tabela
</template>

<script>
import Container from '@components/Container/Container'
import Tabela from '@sections/Coupons/Tabela/Tabela.vue'

export default {
	name: "view-coupons",
	components: {
		Container,
		Tabela
	},
	metaInfo() {
		return {
			title: 'Cupons | Visibox',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Coupons.styl"></style>
