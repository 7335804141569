<template lang="pug">
	section#tabela
		.head
			h2 Sistema de cupons
			button(@click="openCreateCouponModal()") Criar cupom
		table
			thead
				td Cupom
				td Desconto
				td Data Início
				td Data Término
				td Quantidade
				td Uso
				td Status
				td
			tbody(v-for="coupon, index in coupons", v-if="coupons.length")
				td {{coupon.code}}
				td {{coupon.discount}}
				td {{coupon.initialDate | dateFormat}}
				td {{coupon.expirationDate | dateFormat}}
				td {{coupon.useLimit}}
				td {{coupon.discountPeriod > 1 ? `${coupon.discountPeriod} meses` : `${coupon.discountPeriod} mes`}} 
				td {{coupon.status}}
				td 
					button(@click="deleteCoupon(coupon.id)")
						FontAwesomeIcon(:icon="icons.faTrash")
		AsideCreateCoupons(@reloadCoupons="loadCoupons()")



</template>

<script>
import AsideCreateCoupons from '@sections/Coupons/AsideCreateCoupons/AsideCreateCoupons.vue'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default {
	name: "section-tabela-users",
	components: {
		AsideCreateCoupons
	},
	data(){
		return {
			icons: {
				faTrash,
			},
			ativo: null,
			coupons: []
		}
	},
	filters: {
		dateFormat(value) {
			return value.replace(/(\d{4})-(\d{2})-(\d{2})T([\d:]+).*/g, `$3/$2/$1 $4`)
		}
	},
	computed: {
	},
	mounted() {
		this.loadCoupons()
	},
	methods: {
		openCreateCouponModal(){
			this.$store.commit('toggleAside', 'couponModal')
		},
		deleteCoupon(index){
			this.$axios.delete(`coupons/${index}`)
				.then(response => {
					this.loadCoupons()
				})
		},
		loadCoupons() {
			this.$axios.get('coupons/')
				.then(response => {
					this.coupons = response.data.coupons
				})
		},
	}
}
</script>

<style lang="stylus" scoped src="./Tabela.styl"></style>
