<template lang="pug">
	Aside#asideCreateCoupons(:ativo="$store.state.couponModal")
		.container
			h2 Criar um novo cupom
			form(@submit.prevent="createCoupon")
				.input(:class="{'error': errors.code}")
					label(for="cupom") Cupom
					input(type="text", id="cupom", v-model="form.code")
					span(v-if="errors.code") {{errors.code[0]}}
				.group
					.input._33(:class="{'error': errors.discount}")
						label(for="desconto") Desconto
						input(type="number", id="desconto", v-model="form.discount", @change="validateDiscountPercent(form.discount)")
						span(v-if="errors.discount")  {{errors.discount[0]}}
					.input._33(:class="{'error': errors.discountPeriod}")
						label(for="quantidade") Quantidade
						input(type="number", id="quantidade", v-model="form.discountPeriod")
						span(v-if="errors.discountPeriod")  {{errors.discountPeriod[0]}}
					.input._33(:class="{'error': errors.useLimit}")
						label(for="usos") Usos
						input(type="number", id="usos", v-model="form.useLimit")
						span(v-if="errors.useLimit")  {{errors.useLimit[0]}}
				.group
					.input._50(:class="{'error': errors.initial}")
						label(for="inicio") Início
						input(type="datetime-local", id="inicio", v-model="form.initial")
						span(v-if="errors.initial")  {{errors.initial[0]}}
					.input._50(:class="{'error': errors.expiration}")
						label(for="termino") Termino
						input(type="datetime-local", id="termino", v-model="form.expiration")
						span(v-if="errors.expiration")  {{errors.expiration[0]}}
				button(type="submit", v-html="btnText", :disabled='btnDisabled') Criar

</template>

<script>
import Aside from '@components/Aside/Aside'

export default {
	name: "section-aside-create-coupons",
	components: {
		Aside
	},
	data() {
		return {
			btnText: "Criar",
			btnDisabled: false,
			form: {
				code: '',
				discount: 0,
				useLimit: 0,
				initial: '',
				expiration: '',
				discountPeriod: 0,
			},
			errors: {},
		}
	},
	methods: {
		validateDiscountPercent(value){
			if(value > 100)
				this.form.discount = 100
			else if(value < 0)
				this.form.discount = 0
			else
				this.form.discount = value
		},
		createCoupon(){
			this.errors = {}
			this.btnText = "Criando..."
			this.btnDisabled = true

			if (!isNaN(this.form.discount))
  				this.form.discount = +this.form.discount
			if (!isNaN(this.form.useLimit))
  				this.form.useLimit = +this.form.useLimit
			if (!isNaN(this.form.discountPeriod))
  				this.form.discountPeriod = +this.form.discountPeriod

			this.$axios.post('coupons/', this.form)
				.then(response => {
					this.form = {
						code: '',
						discount: 0,
						useLimit: 0,
						initial: '',
						expiration: '',
						discountPeriod: 0,
					}
					this.btnText = "Criado com sucesso"
					setTimeout(() => {
						this.$store.commit('toggleAside', false)
						this.$emit('reloadCoupons')
					}, 3000)
				}).catch(error => {
					this.errors = error.response.data
				}).finally(() => {
					setTimeout(() => {
						this.btnText = 'Criar'
						this.btnDisabled = false
						
					}, 3000)
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./AsideCreateCoupons.styl"></style>
